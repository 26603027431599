import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

import Errors from '../../data/models/Errors'
import { requestInitialState, requestReducers } from '../../helpers/ReduxHelpers'


export const key = 'userAccount'


//
// Initial state
//
const initialState = {
  slug: null,
  data: null,
  loaded: false,
  updateAccount: requestInitialState(),
  updatePassword: requestInitialState(),
  addManager: requestInitialState(),
  deleteManager: requestInitialState(),
  deleteAccount: requestInitialState(),
}

//
// Actions
//
export const actions = {
  setSlug: createAction('userAccount: setSlug', (slug) => ({ slug })),
  setData: createAction('userAccount: setData', (data) => ({ data })),
  setLoaded: createAction('userAccount: setLoaded', (loaded) => ({ loaded })),
  //
  updateAccount: createAction('userAccount: updateAccount', (values) => ({ values })),
  updateAccountSuccess: createAction('userAccount: updateAccountSuccess', (data) => data),
  updateAccountError: createAction('userAccount: updateAccountError', (errors) => Errors(errors)),
  //
  updatePassword: createAction('userAccount: updatePassword', (values) => ({ values })),
  updatePasswordSuccess: createAction('userAccount: updatePasswordSuccess', (data) => data),
  updatePasswordError: createAction('userAccount: updatePasswordError', (errors) => Errors(errors)),
  updatePasswordReset: createAction('userAccount: updatePasswordReset'),
  //
  addManager: createAction('userAccount: addManager', (values) => ({ values })),
  addManagerSuccess: createAction('userAccount: addManagerSuccess', (data) => data),
  addManagerError: createAction('userAccount: addManagerError', (errors) => Errors(errors)),
  //
  deleteManager: createAction('userAccount: deleteManager', (values) => ({ values })),
  deleteManagerSuccess: createAction('userAccount: deleteManagerSuccess', (data) => data),
  deleteManagerError: createAction('userAccount: deleteManagerError', (errors) => Errors(errors)),
  //
  deleteAccount: createAction('userAccount: deleteAccount'),
  deleteAccountSuccess: createAction('userAccount: deleteAccountSuccess', (data) => data),
  deleteAccountError: createAction('userAccount: deleteAccountError', (errors) => Errors(errors)),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setSlug]: (state, { slug }) => Immutable.static.merge(state, { slug }),
  [actions.setData]: (state, { data }) => Immutable.static.merge(state, { data }),
  [actions.setLoaded]: (state, { loaded }) => Immutable.static.merge(state, { loaded }),
  ...requestReducers(actions, 'updateAccount'),
  ...requestReducers(actions, 'updatePassword'),
  ...requestReducers(actions, 'addManager'),
  ...requestReducers(actions, 'deleteManager'),
  ...requestReducers(actions, 'deleteAccount'),
}, initialState)

//
// Selectors
//

const root = (state) => state[key]
const isLoaded = (state) => root(state).loaded
const slug = (state) => root(state).slug
const data = (state) => root(state).data
const updateAccount = (state) => root(state).updateAccount
const updatePassword = (state) => root(state).updatePassword
const addManager = (state) => root(state).addManager
const deleteManager = (state) => root(state).deleteManager

export const selectors = {
  isLoaded,
  slug,
  data,
  updateAccount,
  updatePassword,
  addManager,
  deleteManager,
}
