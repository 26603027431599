import gql from 'graphql-tag'

import { query } from '../../helpers/GraphqlHelpers'


const accountFragmentFragment = gql`
    fragment accountFragmentFragment on User{
        id
        first_name
        last_name
        email
        company {
            id
            owner {
                id
            }
            name
            managers {
                id
                first_name
                last_name
                email
            }
            is_premium
            is_subscribed
        }
    }
`

export const getAccount = (variables, headers) => query(gql`
    query GetAccount {
      me {
          ...accountFragmentFragment
      }
    }
        ${accountFragmentFragment}
  `,
variables, headers)


export const updateAccount = (variables, headers) => query(gql`
    mutation updateAccount(
        $id: ID!,
        $input: UpdateAccountInput!
    ) {
        updateAccount(id: $id, input: $input) {
            ...accountFragmentFragment
        }
    }
        ${accountFragmentFragment}
`, variables, headers)

export const updatePassword = (variables, headers) => query(gql`
    mutation updatePassword($id: ID!, $input: UpdatePasswordInput!) {
      updatePassword(id: $id, input: $input) {
        id
      }
    }
`, variables, headers)
