import gql from 'graphql-tag'


export const AddManager = gql`
    mutation addManager($input: CreateManagerInput!) {
        addManager(input: $input) {
            managers {
                id
                email
                first_name
                last_name
            }
        }
    }
`
