import gql from 'graphql-tag'


export const DeleteComment = gql`
    mutation deleteComment($id: ID!) {
        deleteComment(
            id: $id
        ) {
            id
            content
            company {
                id
            }
        }
    }
`
