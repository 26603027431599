import { query } from '../../../helpers/GraphqlHelpers'

import { CompanyByFullSlug } from './CompanyByFullSlug'
import { AddManager } from './AddManager'
import { DeleteManager } from './DeleteManager'
import { GetCompany } from './getCompany'
import { UpdateCompany } from './updateCompany'
import { GetLocations } from './getLocations'
import { UpdateLocations } from './updateLocations'
import { TopCompanies } from './topCompanies'
import { PostComment } from './PostComment'
import { DeleteComment } from './DeleteComment'


export default {
  addManager: (variables, headers = {}) => query(AddManager, variables, headers),
  byFullSlug: (variables, headers = {}) => query(CompanyByFullSlug, variables, headers),
  deleteManager: (variables, headers = {}) => query(DeleteManager, variables, headers),
  getCompany: (variables, headers = {}) => query(GetCompany, variables, headers),
  getLocations: (variables, headers = {}) => query(GetLocations, variables, headers),
  postComment: (variables, headers = {}) => query(PostComment, variables, headers),
  deleteComment: (variables, headers = {}) => query(DeleteComment, variables, headers),
  topCompanies: (variables, headers = {}) => query(TopCompanies, variables, headers),
  updateCompany: (variables, headers = {}) => query(UpdateCompany, variables, headers),
  updateLocations: (variables, headers = {}) => query(UpdateLocations, variables, headers),
}
