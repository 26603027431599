import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { hideLoading, showLoading } from 'react-redux-loading-bar'

import ApiSagas from '../../redux/api/sagas'
import user from '../../graphql/query/User'
import company from '../../graphql/query/Company'
import { actions as AuthActions, selectors as AuthSelectors } from '../../redux/auth/redux'
import { actions as AppActions } from '../../redux/app/redux'
import { selectors as TranslationSelectors } from '../../redux/i18n/redux'
import app from '../../config/app'
import routes, { Router } from '../../routes'

import { actions, selectors } from './redux'
import { getAccount, updateAccount, updatePassword } from './queries'


export default class UserAccountSagas {

  static* init() {
    yield put(showLoading())

    const result = yield call(ApiSagas.query, getAccount)

    const seo = yield call(UserAccountSagas.transformSeo)

    if (!result.errors) {
      yield all([
        put(actions.setData(result.data.me)),
        put(AppActions.setJsonld(null)),
        put(AppActions.setSeo(seo)),
        put(actions.setLoaded(true)),
        put(hideLoading()),
      ])
    }

    return {}
  }

  static* updateAccount({ payload }) {
    const values = payload.values
    const me = yield select(AuthSelectors.user)
    const data = yield select(selectors.data)
    const input = {
      first_name: values.first_name,
      last_name: values.last_name,
      password: values.password,
      email: values.email,
    }

    const result = yield call(ApiSagas.query, updateAccount, { id: data.id, input })

    if (result.errors && result.errors.length) {
      yield put(actions.updateAccountError(result.errors))
    } else {
      yield put(AuthActions.setUser({ ...me, ...result.data.updateAccount }))
      yield put(actions.setData({ ...data, ...result.data.updateAccount }))
      yield put(actions.updateAccountSuccess(result.data.updateAccount))
    }
  }

  static* updatePassword({ payload }) {
    const values = payload.values
    const data = yield select(selectors.data)
    const input = {
      old_password: values.old_password,
      password: values.password,
      password_confirmation: values.password,
    }

    const result = yield call(ApiSagas.query, updatePassword, { id: data.id, input })

    if (result.errors && result.errors.length) {
      yield put(actions.updatePasswordError(result.errors))
    } else {
      yield put(actions.updatePasswordSuccess(result.data.updatePassword))
    }
  }

  static* addManager({ payload }) {
    const values = payload.values
    const data = yield select(selectors.data)
    const input = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
    }

    const result = yield call(ApiSagas.query, company.addManager, { input })

    if (result.errors && result.errors.length) {
      yield put(actions.addManagerError(result.errors))
    } else {
      yield put(actions.setData({
        ...data,
        company: {
          ...data.company,
          ...result.data.addManager,
        },
      }))
      yield put(actions.addManagerSuccess(result.data.addManager))
    }
  }

  static* deleteManager({ payload }) {
    const values = payload.values
    const data = yield select(selectors.data)
    const params = {
      id: data.company.id,
      managers: [values.id],
    }

    const result = yield call(ApiSagas.query, company.deleteManager, params)

    if (result.errors && result.errors.length) {
      yield put(actions.deleteManagerError(result.errors))
    } else {
      yield put(actions.setData({
        ...data,
        company: {
          ...data.company,
          ...result.data.deleteManager,
        },
      }))
      yield put(actions.deleteManagerSuccess(result.data.deleteManager))
    }
  }

  static* deleteAccount() {
    const result = yield call(ApiSagas.query, user.deleteAccount)

    if (result.errors && result.errors.length) {
      yield put(actions.deleteAccountError(result.errors))
    } else {
      yield put(actions.deleteAccountSuccess(result.data.deleteAccount))
      yield put(AuthActions.logout())
    }
  }

  static* transformSeo(data) {
    if (data) {
      const i18nLang = yield select(TranslationSelectors.lang)
      const i18nStore = yield select(TranslationSelectors.store)
      const companyTitleLabel = i18nStore[i18nLang]?.translation.SEO_company_account_title
      const companyDescriptionLabel = i18nStore[i18nLang]?.translation.SEO_company_account_description

      const seoProps = {
        title: companyTitleLabel,
        image: '',
        description: companyDescriptionLabel,
        url: `${app.APP_URL}${Router.getRouteUrl(routes.userAccount)}`,
        noindex: true,
      }

      return seoProps
    }

    return {}
  }

  static* loop() {
    yield all([
      takeLatest(actions.updateAccount.getType(), UserAccountSagas.updateAccount),
      takeLatest(actions.updatePassword.getType(), UserAccountSagas.updatePassword),
      takeLatest(actions.addManager.getType(), UserAccountSagas.addManager),
      takeLatest(actions.deleteManager.getType(), UserAccountSagas.deleteManager),
      takeLatest(actions.deleteAccount.getType(), UserAccountSagas.deleteAccount),
    ])
  }

}
