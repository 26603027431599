import gql from 'graphql-tag'


export const CompanyCardFragment = {
  fragment: gql`
      fragment companyCard on Company {
          id
          name
          slug
          full_slug
          is_highlighted
          location {
              city
          }
          logo
          pictures {
              image
              is_main
              order
          }
          offers_count
      }
  `,
}
