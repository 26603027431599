import gql from 'graphql-tag'


export const GetCompany = gql`
    query getCompany {
        me {
            company {
                id
                full_slug
                name
                siret
                description
                logo
                video
                creation_year
                workforce
                isActivePremium
                pictures {
                    id
                    image
                    order
                    is_main
                }
                files {
                    id
                    preview_url
                    pdf_url
                    title
                }
                location {
                    name
                    address
                    address_2
                    city
                    zip_code
                }
                links {
                    id
                    type
                    value
                }
            }
        }
        workforces
    }
`
