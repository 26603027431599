import gql from 'graphql-tag'


export const UpdateCompany = gql`
    mutation updateCompanyInformation($id: ID!, $input: UpdateCompanyInput!) {
        updateCompanyInformation(id: $id, input: $input) {
            id
            name
            full_slug
            siret
            workforce
            isActivePremium
            description
            creation_year
            logo
            video
            address
            address_2
            zip_code
            city
            pictures {
                id
                image
                is_main
                order
            }
            files {
                id
                preview_url
                pdf_url
                title
            }
            location {
                name
                address
                address_2
                city
                zip_code
                image
                lat
                long
            }
            links {
                id
                type
                value
            }
        }
    }
`
