import gql from 'graphql-tag'


export const GetLocations = gql`
    query getLocations {
        me {
            company {
                locations {
                    id
                    name
                    address
                    address_2
                    city
                    zip_code
                    is_main
                }
            }
        }
    }
`
