import gql from 'graphql-tag'

import { ShareFragment } from '../Fragments/ShareFragment'


export const CompanyByFullSlug = gql`
    query CompanyByFullSlug($slug: String!) {
        companyByFullSlug(slug: $slug) {
            id
            name
            full_slug
            siret
            description
            logo
            video
            creation_year
            offers_count
            workforce
            isActivePremium
            comments {
                id
                content
                is_recommendation
                user {
                    id
                    slug
                    first_name
                    last_name
                    anonymous
                    avatar
                    company {
                        name
                        slug
                    }
                    companyFunction {
                        id
                        name
                    }
                }
            }
            certifications {
                id
                logo
                name
                type
            }
            custom_certifications
            plain_certifications
            references
            contacts {
                companyFunction {
                    name
                }
                customCompanyFunction
                email
                first_name
                id
                last_name
                phone
            }
            contactMembers {
                companyFunction {
                    name
                }
                email
                first_name
                id
                last_name
                slug
            }
            phone
            domains {
                id
                name
            }
            pictures {
                id
                image
                order
                is_main
            }
            files {
                id
                preview_url
                pdf_url
                title
            }
            location {
                name
                address
                address_2
                city
                zip_code
                image
                lat
                long
            }
            locations {
                name
                address
                address_2
                city
                zip_code
                lat
                long
                is_main
            }
            links {
                id
                type
                value
            }
            share {
                ...share
            }
        }
    }

    ${ShareFragment.fragment}
`
