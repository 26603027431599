import gql from 'graphql-tag'


export const PostComment = gql`
    mutation createComment($input: CreateCommentInput!) {
        createComment(
            input: $input
        ) {
            id
            content
            is_recommendation
            user {
                id
                slug
                first_name
                last_name
                anonymous
                avatar
                company {
                    name
                    slug
                }
                companyFunction {
                    id
                    name
                }
            }
        }
    }
`
