import gql from 'graphql-tag'

import { CompanyCardFragment } from '../Fragments/CompanyCardFragment'


export const TopCompanies = gql`
    query TopCompanies($first: Int!) {
        topCompanies(first: $first) {
            paginatorInfo {
                total
            }
            data {
                ...companyCard
            }
        }
    }

    ${CompanyCardFragment.fragment}
`
