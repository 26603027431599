import gql from 'graphql-tag'


export const DeleteManager = gql`
    mutation deleteManager($id: ID!, $managers: [ID!]) {
        deleteManager(
            id: $id,
            managers: {
                delete: $managers
            }
        ) {
            managers {
                id
                email
                first_name
                last_name
            }
        }
    }
`
