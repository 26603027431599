import gql from 'graphql-tag'


export const UpdateLocations = gql`
    mutation updateLocations($id: ID!, $locations: UpdateLocationRelation) {
        locations(id: $id, locations: $locations) {
            locations {
                id
                name
                address
                address_2
                city
                zip_code
                is_main
            }
        }
    }
`
