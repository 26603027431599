import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'i18n'

//
// Initial state
//
const initialState = {
  lang: null,
  store: null,
  locales: [
    {
      iso: 'fr',
      label: 'Français',
      fallback: null,
      __typename: 'Locale',
    },
  ],
  configuration: {},
}

//
// Actions
//
export const actions = {
  setLang: createAction('I18n: setLang', (lang) => ({ lang })),
  setLocales: createAction('I18n: setLocales', (locales) => ({ locales })),
  setStore: createAction('I18n: setStore', (store) => ({ store })),
  setConfiguration: createAction('I18n: setConfiguration', (configuration) => ({ configuration })),
  saveLang: createAction('I18n: saveLang', (lang, store) => ({
    lang,
    store,
  })),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.saveLang]: (state, { lang, store }) => Immutable.static.merge(state, {
    lang,
    store,
  }),
  [actions.setStore]: (state, { store }) => Immutable.static.merge(state, { store }),
  [actions.setLocales]: (state, { locales }) => Immutable.static.merge(state, { locales }),
  [actions.setConfiguration]: (state, { configuration }) => Immutable.static.merge(state, { configuration }),
}, initialState)

//
// Selectors
//
const root = (state) => state[key]
const lang = (state) => root(state).lang
const store = (state) => root(state).store
const locales = (state) => root(state).locales
const configuration = (state) => root(state).configuration

export const selectors = {
  lang,
  store,
  locales,
  configuration,
}
